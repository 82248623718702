@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

root {
  background-color: #c47458;
}

body:-webkit-full-screen, root:-webkit-full-screen {
  width: 100%;
  height: 100%;
}


.stairs-arrow {
  transform: scale(0.8);
}

.external-nav-arrow {
  opacity: 0.60;
  transform: scale(0.8);
}

.slide-container > div, .react-slideshow-container, .react-slideshow-fadezoom-wrapper, .react-slideshow-fadezoom-images-wrap, .images-wrap, .react-slideshow-wrapper, .react-slideshow-fadezoom-images-wrap > div  {
  height: 100%;
}

.icona-duomo {
  width: 70px;
  height: 70px;
  z-index: 1!important;
}